import { viewsetActions, request } from '@/service/plugin';

const osDatahubCache = {
  token: null,
  expiry: null,
};

const getOsDatahubToken = async (options) => {
  const now = new Date();
  if (!osDatahubCache.expiry || now >= osDatahubCache.expiry) {
    const path = '/api/s/case-addresses/os-datahub-token/';
    const resp = await request('post', path, { ...options, skipSuccessMessage: true });
    if (resp.status === 200) {
      osDatahubCache.token = resp.data.access_token;
      osDatahubCache.expiry = new Date(now.getTime() + 1000 * resp.data.expires_in);
    }
  }
  return osDatahubCache.token;
};

export default {
  cases: viewsetActions('cases', {
    basePath: '/api/s/',
    extraActions: {
      portal: { path: 'portal', method: 'get', detail: true },
      completeSection: { path: 'complete-section', method: 'post', detail: true },
      submit: { path: 'submit', method: 'post', detail: true },
      countryMap: { path: 'country-map', method: 'get', detail: false },
      getStripeDetails: { path: 'stripe-details', method: 'get', detail: true },
    },
  }),
  caseActivities: viewsetActions('case-activities', { basePath: '/api/s/' }),
  caseBasicDetails: viewsetActions('case-basic-details', { basePath: '/api/s/' }),
  caseBirthDetails: viewsetActions('case-birth-details', { basePath: '/api/s/' }),
  casePassports: viewsetActions('case-passports', { basePath: '/api/s/' }),
  caseDrivingLicences: viewsetActions('case-driving-licences', { basePath: '/api/s/' }),
  caseOtherNames: viewsetActions('case-other-names', { basePath: '/api/s/' }),
  caseAddresses: {
    ...viewsetActions('case-addresses', {
      basePath: '/api/s/',
      extraActions: { osFakeLookup: { path: 'os-fake-lookup', method: 'get', detail: false } },
    }),
    osDatahubToken: getOsDatahubToken,
  },
  caseDocuments: viewsetActions('case-documents', {
    basePath: '/api/s/',
    extraActions: {
      submitSelection: { path: 'submit-selection', method: 'post', detail: false },
      clearSelection: { path: 'clear-selection', method: 'post', detail: false },
      deleteFile: { path: 'delete-file', method: 'post', detail: true },
    },
  }),
  caseDBSDetails: viewsetActions('case-dbs-details', { basePath: '/api/s/' }),
  caseOnlineIdv: viewsetActions('case-online-idv', {
    basePath: '/api/s/',
    extraActions: {
      startSession: { path: 'start-session', method: 'post', detail: true },
      restartSession: { path: 'restart-session', method: 'post', detail: true },
      clientSuccess: { path: 'client-success', method: 'post', detail: true },
      setManualIdv: { path: 'set-manual-idv', method: 'post', detail: true },
      requestResultEmail: { path: 'request-result-email', method: 'post', detail: true },
      resolveNameMismatch: { path: 'resolve-name-mismatch', method: 'post', detail: true },
    },
  }),
};
